var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"main",staticClass:"relative main",attrs:{"id":"app"}},[_c('v-app',[_c('div',{class:{ opacity0: _vm.loading2 }},[(
          _vm.$route.path == '/hikari' ||
          _vm.$route.path == '/company' ||
          _vm.$route.path == '/privacy' ||
          _vm.$route.path == '/estimate' ||
          _vm.$route.path == '/estate' ||
          _vm.$route.path == '/comfirm' ||
          _vm.$route.path == '/thankyou' ||
          _vm.$route.path == '/contact'
        )?_c('div',{class:{ opacity0: _vm.loading }},[_c('Header'),_c('div',{staticClass:"clear"}),_c('router-view',{staticClass:"relative pt-12 overflow-hidden"}),(!_vm.userpath && !_vm.loading)?_c('Footer'):_vm._e(),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('h3',{staticClass:"f-gray mb-6 pt-5"},[_vm._v("MENU")]),_vm._l((_vm.rinks),function(r){return _c('div',{key:r.path,staticClass:"my-3"},[_c('router-link',{class:{
                underline: _vm.$route.path == r.path,
                noevent: _vm.$route.path == r.path,
              },attrs:{"to":r.path}},[_vm._v(_vm._s(r.name))])],1)})],2)],1):_vm._e(),_c('div',{class:{
          'lighten-5': _vm.pathname == '/staff',
          'light-blue': _vm.pathname == '/staff',
        }},[_c('Header2',{staticClass:"opacity0",class:{ 'fade-in': !_vm.menuLoading && _vm.menuShow }}),_c('router-view',{staticClass:"overflow-x-hidden",attrs:{"MenuLoading":_vm.menuLoading,"Loading2":_vm.loading2}}),_c('div',{staticClass:"opacity0",class:{ 'fade-in': !_vm.footerLoading && _vm.menuShow }},[_c('Footer2')],1)],1)]),_c('div',{staticClass:"absolute_xy text-center crebou_loading noevent",class:{ opacity0: _vm.loading2 != 1 }},[_c('div',{staticClass:"opacity0",class:{ 'fade-in': _vm.loading2 == 1 }},[_c('img',{staticClass:"crebou filter-shadow",attrs:{"src":require("@/assets/img/crebou_loading.png")},on:{"load":function($event){_vm.loading2 == 2 ? (_vm.loading2 = 1) : ''}}}),_c('div',{staticClass:"noto9 f-12 f-blue"},[_vm._v(" Loading "),_vm._l((5),function(i){return _c('span',{key:i,class:'opacity0 dot' + i},[_vm._v(".")])})],2)])])]),(!_vm.loading2)?_c('div',_vm._l((_vm.imgs),function(i){return _c('img',{key:i,staticClass:"absolute t-0 l-0 opacity0 noevent",staticStyle:{"width":"1px","height":"1px"},attrs:{"src":require('@/assets/img/' + i)}})}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }