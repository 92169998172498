<template>
  <div class="footer2 fade-in opacity0 overflow-x-hidden" ref="footer" >
    <img
      src="@/assets/img/footer.svg"
      class="relative l--10 b--10 z-0 opacity0"
      :class="{ 'bottom-up2': show }"
      style="width:calc(100% + 20px)"
    />
    <div class="brown darken-4 grand z-1 relative f-white text-center">
      <div class="mt-8 inline-block">
        <div
          v-for="(m, i) in $route.path == '/' ? menus : menus2"
          :key="m.url"
          class=" float-left icon"
        
        >
          <router-link
            :to="m.url"
            v-html="
              screen
                ? '<i class=\' fa-lg ' +
                  icons[i].icon +
                  '\'></i><br><div class=\'mt-2 f-09\'>' +
                  icons[i].text
                : m.name + '</div>'
            "
            class="f-white pointer"
            v-if="$route.path != m.url && m.url.slice(0, 1) != '#'"
          />
          <div
            v-scroll-to="m.url"
            v-html="
              screen
                ? '<i class=\' fa-lg ' +
                  icons[i].icon +
                  '\'></i><br><div class=\'mt-2 f-09\'>' +
                  icons[i].text
                : m.name + '</div>'
            "
            class="f-white pointer"
            v-if="
              $route.path != m.url && m.url.slice(0, 1) == '#' && m.url != '/'
            "
          />
          <div
            @click="top"
            v-html="
              screen
                ? '<i class=\' fa-lg ' +
                  icons[i].icon +
                  '\'></i><br><div class=\'mt-2 f-09\'>' +
                  icons[i].text
                : m.name + '</div>'
            "
            class="f-white pointer"
            v-if="$route.path == m.url && m.url.slice(0, 1) != '#'"
          />
        </div>
        <div class="clear" />
      </div>
      <div class="bold pt-4">&copy;&nbsp;CREVo</div>
    </div>
  </div>
</template>

<script>
import Scroll from "@/mixins/scroll";
import Menu from "@/mixins/menu2";
import d from "@/d";
export default {
  mixins: [Scroll, Menu],
  data() {
    return {
      show: 0,
      screen: "",
    };
  },
  async mounted() {
    if (window.innerWidth < 960) {
      this.screen = "sm";
    }
    if (window.innerWidth < 480) {
      this.screen = "xxs";
    }
  },
  methods: {
    top() {
      window.scroll({ top: 0, behavior: "smooth" });
      this.drawer = false;
    },
    async scroll01() {
      if (
        window.innerHeight + this.scroY >
          this.$refs.footer.getBoundingClientRect().top * 2 &&
        !this.show
      ) {
        await d.sleep(500);
        this.show = 1;
      }
    },
  },
  watch: {
    scroY() {
      this.scroll01();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/footer2.scss";
</style>
