

const sleep = function(time) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, time);
    });
};

const smooth = async function(element, smooth) {
    await sleep(50);
    document.getElementById(element).scrollIntoView({
        behavior: smooth,
        block: "start",
        inline: "nearest"
    });
};



export default {
    sleep,
    smooth,
};
