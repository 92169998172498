export default {
    async mounted() {
        let title = "";
        const site = "不動産のクレボ";

        switch (location.pathname) {
            case "/user":
                title = "管理画面トップ";
                break;
            case "/user/company":
                title = "会社情報の入力";
                break;
            case "/user/holiday":
                title = "休業日の設定";
                break;
            case "/user/price":
                title = "料金の設定";
                break;
            case "/user/interval":
                title = "予約の設定";
                break;
            case "/user/payment":
                title = "支払方法の選択";
                break;
            case "/user/account":
                title = "アカウント情報";
                break;
            case "/user/contact":
                title = "お問合せ";
                break;
            case "/user/terms":
                title = "管理画面利用規約";
                break;
            case "/user/history":
                title = "施工申込履歴";
                break;
            case "/user/station":
                title = "ガソリンスタンドの登録";
                break;
            case "/company":
                title = "会社概要";
                break;
            case "/privacy":
                title = "プライバシーポリシー";
                break;
            case "/contact":
                title = "お問合せ";
                break;
            case "/user/verify":
                title = "ワンタイムパスワードの入力";
                break;
            case "/estimate":
                title = "簡単お見積";
                break;
            case "/estate":
                title = "物件情報入力";
                break;
            case "/comfirm":
                title = "お申込内容の確認";
                break;
            case "/thankyou":
                title = "お申し込みが完了いたしました";
                break;
            case "/hikari":
                title = "CREVoの光触媒コーティング";
                break
            case "/real_estate":
                title = "不動産のご相談";
                break
            // case "/sale":
            //     title = "売却をご検討のお客様";
            //     break
            // case "/buy":
            //     title = "購入をご検討のお客様";
            //     break
            case "/staff":
                title = "笑談スタッフの紹介";
                break

            case "/estate/buy/used/mansion":
            case "/estate/buy/used/kodate":
            case "/estate/buy/new/kodate":
            case "/estate/rent/sumai":
            case "/estate/rent/store":
                title = "物件検索";
                break


            case "/estate/count":
                title = "物件のご案内";
                break

            case "/user/access":
                title = "アクセス数";
                break;

            case "/user/estate":
                title = "物件リストアップロード";
                break;

                case "/register":
                    title = "無料会員登録";
                    break
            default:
                title = "ページが存在しません";
        }

        if (location.pathname.indexOf("/detail") != -1) {
            title = "物件詳細";
        }

        if (location.pathname == "/") {
            window.document.title = site
        } else {
            window.document.title = title + "  ｜ " + site;
        }
    },
};
