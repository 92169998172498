var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"footer",staticClass:"footer2 fade-in opacity0 overflow-x-hidden"},[_c('img',{staticClass:"relative l--10 b--10 z-0 opacity0",class:{ 'bottom-up2': _vm.show },staticStyle:{"width":"calc(100% + 20px)"},attrs:{"src":require("@/assets/img/footer.svg")}}),_c('div',{staticClass:"brown darken-4 grand z-1 relative f-white text-center"},[_c('div',{staticClass:"mt-8 inline-block"},[_vm._l((_vm.$route.path == '/' ? _vm.menus : _vm.menus2),function(m,i){return _c('div',{key:m.url,staticClass:"float-left icon"},[(_vm.$route.path != m.url && m.url.slice(0, 1) != '#')?_c('router-link',{staticClass:"f-white pointer",attrs:{"to":m.url},domProps:{"innerHTML":_vm._s(
            _vm.screen
              ? '<i class=\' fa-lg ' +
                _vm.icons[i].icon +
                '\'></i><br><div class=\'mt-2 f-09\'>' +
                _vm.icons[i].text
              : m.name + '</div>'
          )}}):_vm._e(),(
            _vm.$route.path != m.url && m.url.slice(0, 1) == '#' && m.url != '/'
          )?_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:(m.url),expression:"m.url"}],staticClass:"f-white pointer",domProps:{"innerHTML":_vm._s(
            _vm.screen
              ? '<i class=\' fa-lg ' +
                _vm.icons[i].icon +
                '\'></i><br><div class=\'mt-2 f-09\'>' +
                _vm.icons[i].text
              : m.name + '</div>'
          )}}):_vm._e(),(_vm.$route.path == m.url && m.url.slice(0, 1) != '#')?_c('div',{staticClass:"f-white pointer",domProps:{"innerHTML":_vm._s(
            _vm.screen
              ? '<i class=\' fa-lg ' +
                _vm.icons[i].icon +
                '\'></i><br><div class=\'mt-2 f-09\'>' +
                _vm.icons[i].text
              : m.name + '</div>'
          )},on:{"click":_vm.top}}):_vm._e()],1)}),_c('div',{staticClass:"clear"})],2),_c('div',{staticClass:"bold pt-4"},[_vm._v("© CREVo")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }