export default {
    data() {
        return {
            menus: [
                {
                    url: "/",
                    name: "トップ"
                },
                {
                    url: "#company",
                    name: "会社案内"
                },
                {
                    url: "/real_estate",
                    name: "不動産のご相談"
                },
                {
                    url: "#order",
                    name: "お問合せ"
                },
            ],
            menus2: [
                {
                    url: "/",
                    name: "トップ"
                }, {
                    url: "/#company",
                    name: "会社案内"
                },
                {
                    url: "/real_estate",
                    name: "不動産のご相談"
                },
                {
                    url: "/#order",
                    name: "お問合せ"
                },
            ],
icons:[
    {
        icon:"fas fa-chevron-up",
        text:"トップ"
    },
    {
        icon:"fas fa-building",
        text:"会社案内"
    },
    {
        icon:"fas fa-home",
        text:"不動産の<br class='dis-xxs'>ご相談"
    },
    {
        icon:"fas fa-comment-alt",
        text:"問合せ"
    },
]
        }
    },
}