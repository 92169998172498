import axios from "axios";
const state = {
    user: {},
    info: {},
    holiday: {},
    obon: {},
    bank: {},
    station: {},
    tatami: 1.63,
};

const mutations = {
    async login(state, data) {
        state.user = data.user;
        state.info = data.info;
        state.holiday = data.holiday;
        state.obon = data.obon;
        state.station = data.station;
        state.bank = data.bank;
    },
    bankSave(state, data) {
        state.bank = data.bank;
    },
    save(state, data) {
        state.user = data.user;
        state.info = data.user;
    },
    emailChange(state, data) {
        let user = state.user;
        state.user = null;
        user.email = data;
        state.user = user;
        state.info = user;
    },
    verifyChange(state, data) {
        let user = state.user;
        state.user = null;
        user.email_verified = data;
        state.user = user;
        state.info = user;
    },
    stationSave(state, data) {
        state.station = data;
    },
};
const actions = {
    async login(context) {
        const response = await axios.post("/user/login");
        context.commit("login", response.data);
    },
    holidaySave(context, data) {
        context.commit("login", data);
    },
    paymentSave(context, data) {
        context.commit("save", data);
        if (data.bank) {
            context.commit("bankSave", data);
        }
    },
    emailChange(context, data) {
        context.commit("emailChange", data);
    },
    verifyChange(context, data) {
        context.commit("verifyChange", data);
    },
    stationSave(context, data) {
        context.commit("stationSave", data);
    },
    save(context, data) {
        context.commit("save", data);
    },
};
const getters = {
    user: (state) => state.user,
    holiday: (state) => state.holiday,
    obon: (state) => state.obon,
    info: (state) => state.info,
    bank: (state) => state.bank,
    station: (state) => state.station,
    tatami: (state) => state.tatami,
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
